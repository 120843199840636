<template lang="html">
  <section>
    <section v-if="isLoading" class="container container--center">
      <BasicLoader />
    </section>
    <section v-else>
      <div class="register" v-if="item && item.status == 'open'">
        <div class="register__details mb-40">
          <h3 class="title-uppercase">{{ item.team.name }}</h3>
          <h1 class="mb-0">{{ item.name }}</h1>
          <h3 class="text-reg mb-0">
            {{ convertTime(item.date) | moment('DD MMMM YYYY HH:mm') }}
          </h3>
          <h4 class="text-reg">{{ item.address }}</h4>
          <div class="register__division mt-16" v-if="item.division">
            <h5>{{ item.division.name }}</h5>
            <p
              class="text-lg"
              v-if="item.division.from_birthday || item.division.to_birthday">
              This event is restricted to players born
              <span class="text-bold" v-if="item.division.from_birthday"
                >after
                {{ item.division.from_birthday | moment('MMMM Do YYYY') }}</span
              >
              <span
                v-if="item.division.from_birthday && item.division.to_birthday"
                >and</span
              >
              <span v-if="item.division.to_birthday" class="text-bold">
                before
                {{ item.division.to_birthday | moment('MMMM Do YYYY') }}</span
              >.
            </p>
            <p class="text-lg" v-if="item.division.year_eligible">
              This event is restricted to players born on or after 1 January of
              {{ item.division.year_eligible }}.
            </p>
          </div>
        </div>

        <section :key="loaded">
          <div v-if="!isAuthenticated">
            <div v-if="!signup">
              <h3 class="title-3">Log in to apply!</h3>
              <div>
                <router-link
                  :to="{ name: 'login', query: { redirect: this.$route.path } }"
                  class="btn btn--outlined mt-16 mb-8"
                  >Log in</router-link
                >
                <div>
                  <button class="clickable nav-link" @click="handleCreation()">
                    or create a new account.
                  </button>
                </div>
              </div>
            </div>

            <div v-else>
              <h3 class="title-3">Sign up!</h3>
              <p class="text-xlg text-semi">
                Complete the signup form to take part
              </p>
              <RegisterPlayer
                @init="handleInit"
                :division="item.division"></RegisterPlayer>
              <router-link
                :to="{ name: 'login', query: { redirect: this.$route.path } }"
                class="clickable nav-link inline-block mt-16"
                >Already registered? Log in!</router-link
              >
            </div>
          </div>

          <div v-if="isAuthenticated && submissionsLoaded && !hasApplied">
            <h3 class="title-3">Welcome {{ player.name }}!</h3>
            <p class="text-xlg text-semi">
              You can sign up with your existing credentials, just confirm your
              submission!
            </p>
            <button class="btn btn--primary mt-32" @click="createSubmission">
              Submit my application to this event
            </button>
          </div>

          <div
            v-if="
              isAuthenticated && submissionsLoaded && hasApplied && wasUnapplied
            ">
            <h3 class="title-3">{{ player.name }},</h3>
            <p class="text-xlg text-semi">Thank you for your submission!</p>
            <button class="btn btn--outlined mt-32" @click="redirectToEvents">
              Go to your profile
            </button>
          </div>

          <div
            v-if="
              isAuthenticated &&
              submissionsLoaded &&
              hasApplied &&
              !wasUnapplied
            ">
            <h3 class="title-3">{{ player.name }},</h3>
            <p class="text-xlg text-semi">
              You've already submitted your application to this event!
            </p>
            <button class="btn btn--outlined mt-32" @click="redirectToEvents">
              Check events you've applied to
            </button>
          </div>
        </section>
      </div>
      <div class="register" v-if="item && item.status == 'closed'">
        <div class="register__details mb-40">
          <h3 class="title-uppercase">{{ item.team.name }}</h3>
          <h1>{{ item.name }}</h1>
          <h2 class="text-reg">{{ item.address }}</h2>
        </div>
        <h3 class="title-3">Registration for this event is already closed!</h3>
        <p class="text-xlg text-semi">
          Unfortunatelly you can't sign up to this event anymore !
        </p>
      </div>
      <div
        class="register"
        v-if="
          item == false ||
          (item && (item.status == 'draft' || item.status == 'pending'))
        ">
        <div class="register__details mb-40">
          <h1>Event not found!</h1>
          <h2 class="text-reg">Make sure you have the correct url!</h2>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
  import api from '@/api/index';
  import { mapState } from 'vuex';

  import TimeHelper from '@/helpers/TimeHelper';
  import RegisterPlayer from '@/components/Forms/RegisterPlayer';
  import BasicLoader from '@/components/Loaders/Basic';

  export default {
    name: 'EventRegistration',
    components: { RegisterPlayer, BasicLoader },
    data: function () {
      return {
        item: null,
        signup: false,
        restriction: null,
        wasUnregistered: true,
        wasUnapplied: false,
        isLoading: true,
      };
    },
    beforeMount() {
      api.http
        .get(`registrations/${this.$route.params.slug}`)
        .then((res) => {
          this.item = res.body;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.status == 404) {
            this.item = false;
            this.isLoading = false;
          }
        });

      if (this.isAuthenticated) {
        this.$store.dispatch('submissions/GET_PLAYER_SUBMISSIONS');
        this.wasUnregistered = false;
      }
    },
    methods: {
      convertTime: TimeHelper.convertUtcToLocal,
      handleCreation() {
        this.signup = true;
      },
      createSubmission() {
        this.wasUnapplied = true;
        this.$store.dispatch('submissions/SUBMIT_APPLICATION', {
          slug: this.$route.params.slug,
        });
      },
      redirectToEvents() {
        this.$router.push('/events');
      },
      handleInit() {
        this.wasUnregistered = true;
        this.wasUnapplied = true;
      },
    },
    computed: {
      ...mapState({
        player: (state) => state.user.profile,
        submissions: (state) => state.submissions.submitted,
        loaded: (state) => state.submissions.status,
        user: (state) => state.user.profile,
      }),
      hasApplied() {
        return this.submissions
          ? this.submissions.filter((app) => app.event_id == this.item.id)
              .length > 0
          : null;
      },
      submissionsLoaded() {
        return this.loaded == 'success';
      },
    },
  };
</script>

<style lang="css" scoped></style>
